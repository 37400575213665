
/* previous dark color was rgb(120,6,6)*/

html {
	scrollbar-gutter: stable;
  }

* { box-sizing: border-box; padding: 0; margin: 0; }
.page-home { 
	background-color: #fff5ee; 
	padding: 0;
}

.no-break {
	white-space: nowrap;
}

.top-bar {
	display:block; 
	height: 1px; 
	border:0; 
	border-top: 1px solid rgb(0,0,0);
	margin: 1em 0;
	padding: 0;
	margin-left: 30px;
    width: 50%;
	@media (max-width: 769px) {
		margin: 0.5em 0;
	}
}

.hsg-container {
	border-top: 4px solid #ffc8a1;
	.hsg-body {
		padding-top: 20px;
		max-width: 1280px;
		margin: 0 auto;
		background: white;
		h1 {
			font-size: calc(14px + 2vmin);
			font-family: "Montserrat", sans-serif;
			font-weight: 700;
			font-style: italic;
			color: black;
			padding: 10px 0 0 30px;
			transition: all 1.0s;
		}
		h2 {
			font-size: calc(14px + 1vmin);
			font-family: "Montserrat", sans-serif;
			font-weight: 300;
			color: black;
			padding: 0 0 10px 30px;
			transition: all 1.0s;
		}
	}
	.hsg-footer {
		background: #e1e1e1;
		max-width: 1280px;
		min-height: 40px;
		margin: 0 auto;
		border-top: 4px solid #ffc8a1;
		.social-links {
			padding: 15px 20px 10px;
			display: flex;
			justify-content: flex-end;
			img {
				width: 20px;
				margin-left: 20px;
			}
		}
		
	}
}

/* Tab Container */
.top-tabs {
	width: 100%;
	height: auto;
	min-height: 600px;
	padding: 2rem 1rem;
	padding-left: 30px;
	color: #E8F0F2;
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
	transition: all 2.0s;
	@media (max-width: 769px) {
		padding: 1rem 0 2rem;
	}
	ul.nav {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@media (max-width: 769px) {
			padding-left: 30px;
		}
	}
	ul.nav li {
		width: 7%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: .5rem;
		list-style: none;
		text-align: center;
		cursor: pointer;
		transition: all 0.7s;
		font-family: "Montserrat", sans-serif;
		font-weight: 600;
		min-height: 40px;
		font-size: 12px;
		color: black;
		@media (max-width: 769px) {
			width: 20%;
			min-height: 40px;
		}
	}
	ul.nav li:hover {
		background: rgba(120,120,120,0.25);
	}
	ul.nav li.active {
		background: black;
		color: white;
		font-weight: 500;
	}
	.bio {
		margin-top: 40px;
		padding: 0 30px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		max-width: 1200px;
		@media (max-width: 769px) {
			flex-direction: column;
		}
		div {
			align-self: center;
			p {
				text-align: left;
				margin: 10px 30px;
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				color: black;
				font-family: "Special Elite", system-ui;
				&.opening-line {
					text-align: center;
					margin: 0 5px;
					margin-top: 20px;
					margin-bottom: 20px;
					font-size: 18px;
				}
				@media (max-width: 769px) {
					width: 100%;
					margin: 0;
				}
			}
		}
		img {
			width: 300px;
			margin: 10px 0;
			@media (max-width: 769px) {
				width: 100%;
			}
		}
	}
 }

 .work-tabs {
	width: 100%;
	height: auto;
	margin-top: 40px;
	color: black;
	border-radius: 2rem;
	@media (max-width: 769px) {
		padding: 1rem 0;
		margin-top: 10px;
	}
	.react-multi-carousel-track {
		margin-top: 30px;
	}
	.react-multi-carousel-dot-list {
		top: 0;
		height: 40px;
	}
	.react-multiple-carousel__arrow {
		top: 0;
		margin-top: 15%;
		background: black;
		@media (max-width: 769px) {
			min-width: 30px;
    		min-height: 30px;
			margin-top: 25%;
		}
		&:before {
			color: #eeeeee;
			font-weight: 700;
		}
		&:hover {
			background: #eee;
			&:before {
				color: black;
				font-weight: 700;
			}
		}
	}
 }

/* Carousel */



/* First and Second Tab Styles */
.oneijah p,
.extinction-love p,
.firegate-four p,
.psychometrics p {
	font-size: 2rem;
	text-align: center;
}

.comps {
	display: flex;
	flex-wrap: wrap;
	@media (max-width: 769px) {
		flex-direction: column;
	}
	p {
		margin-right: 30px;
		margin-bottom: 0 !important;
		width: 40%;
		@media (max-width: 769px) {
			width:  inherit;
			margin-right: 0;
		}
	}
	p:last-of-type {
		margin-bottom: 10px !important;
	}
	p.full-width {
		width: 100%;
	}
}

.bookPanel {
	display: flex;
    width: 80%;
    margin: 0 auto;
	margin-top: 30px;
	@media (max-width: 769px) {
		flex-direction: column;
		margin-top: 10px;
	}
	.textContainer {
		width: 65%;
		margin-top: 30px;
		text-align: left;
		@media (max-width: 769px) {
			margin-top: 10px;
			width: 90%;
			margin-left: 20px;
		}
		h3 {
			font-weight: 500;
			font-size: 13px;
			text-decoration: underline;
		}
		h4 {
			font-size: 18px;
			font-weight: 600;
			font-family: "Montserrat", sans-serif;
			text-align: left;
			margin-bottom: 10px;
		}
		p {
			font-size: 13px;
			font-family: "Montserrat", sans-serif;
			text-align: left;
			margin-bottom: 10px;
		}
		hr {
			border: none;
			height: 4px;
			width: 100px;
			margin-bottom: 10px;
			background: black;
			&:last-of-type {
				width: 90%;
				margin-bottom: 25px;
			}
		}
		.read-more-text-o,
		.read-more-text-el,
		.read-more-text-ff,
		.read-more-text-psy {
			max-height: 0;
			overflow: hidden;
			transition: max-height 1s ease-out;
			margin-top: 15px;
		}
		.read-more-text-o.show,
		.read-more-text-el.show,
		.read-more-text-ff.show,
		.read-more-text-psy.show {
			max-height: 500px;
			@media (max-width: 769px) {
				max-height: 1000px;
			}
		}
		button {
			border: none;
			display: block;
			margin: 10px 0;
			font-size: 14px;
			font-weight: 700;
			padding: 5px 15px;
			cursor: pointer;
			font-family: "Montserrat", sans-serif;
			transition: all 1s ease-out;
		}
	}
	.imageContainer {
		width: 35%;
		height: auto;
		max-height: 542px;
		margin: 20px auto;
		background-color: black;
		padding: 5px;
		@media (max-width: 769px) {
			width: 70%;
			margin-top: 5px;
		}
		img {
			width: 100%;
		}
	}
}

.extinction-love {
	@media (max-width: 769px) {
		flex-direction: column-reverse;
	}
	.imageContainer {
		background-color: white;
	}
	.textContainer {
		hr {
			background: rgb(190, 249, 247);
		}
	}
}

.oneijah {
	.textContainer {
		margin-left: 20px;
		hr {
			background: rgb(170,0,0);
		}
	}
	.imageContainer {
		background-color: rgb(240, 240, 240);
	}
}

.firegate-four {
	@media (max-width: 769px) {
		flex-direction: column-reverse;
	}
	.textContainer {
		margin-right: 10px;
		hr {
			background: rgb(239, 102, 5);
		}
	}
	.imageContainer {
		background-color: rgb(239, 102, 5);
	}
}

.psychometrics {
	.textContainer {
		margin-left: 20px;
		hr {
			background: rgb(0, 253, 255);
		}
	}
	.imageContainer {
		background-color: rgb(0, 253, 255);
	}
}